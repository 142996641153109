@import 'Styles/Kulturama/includes';

/**
*
* FilterEventInput
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.FilterEventInput {
    $root: &;

    display: block;
    margin-right: 10px;
    padding-bottom: 10px;
    font-size: 1.4rem;
    font-weight: $normal;
    line-height: 17px;
    width: 100%;

    @include media(xs) {
        width: calc(50% - 10px);
    }

    @include media(sm) {
        width: auto;
        margin-right: 20px;
    }

    &--Grouped {
        width: calc(50% - 10px);

        @include media(xs) {
            width: calc(#{percentage(1/3)} - 10px);
        }

        @include media(sm) {
            width: 100%;
            margin-right: 10px;
        }
    }

    &--Button {
        width: calc(100% - 9px);
        margin-left: 9px;
        margin-right: 0;
        padding-bottom: 9px;

        @include media(xs) {
            width: calc(50% - 9px);
        }

        @include media(sm) {
            width: calc(#{percentage(1/3)} - 9px);
        }

        @include media(md) {
            width: calc(50% - 9px);
        }
    }

    &--Tag {
        display: inline-block;
        width: auto!important;
        line-height: 1;
        padding: 0;
        margin-right: 12px!important;
        margin-bottom: 8px;
        border: none;
    }

    &--Disabled {
        opacity: .6;
    }

    &__Input {
        display: none;
    }

    &__Label {
        position: relative;
        display: flex;
        padding-left: 30px;
        transition: all .2s;

        #{$root}:not(#{$root}--Disabled) & {
            cursor: pointer;
        }

        #{$root}--Button & {
            padding: 13px 19px;
            border: 1px solid #e3e3e3;
            border-radius: 3px;
            background: white;
        }

        #{$root}--Button #{$root}__Input:checked ~ & {
            background-color: palette(black, 5);
        }

        #{$root}--Tag & {
            padding: 4px 5px 6px;
            color: palette(black, 78);
            border: 1px solid palette(black, 5);
            background-color: palette(black, 5);
            transition: all .2s ease-in-out;
        }

        #{$root}--Tag:hover & {
            color: palette(purple);
            border-color: palette(purple);
            background-color: white;
        }

        #{$root}--Tag #{$root}__Input:checked ~ & {
            color: white;
            border-color: palette(purple);
            background-color: palette(purple);
        }

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 16px;
            height: 16px;
            border: 1px solid #d0d0d0;
            border-radius: 2px;
            background: white;
            transition: opacity .2s;

            #{$root}--Button &,
            #{$root}--Tag & {
                display: none;
            }
        }

        &::after {
            border-color: palette(pink);
            background: palette(pink) url(../#{$basepath}img/bg-icons/kulturama/check--white.svg);
            background-repeat: no-repeat;
            background-position: center center;
            opacity: 0;

            #{$root}__Input:checked ~ & {
                opacity: 1;
            }
        }
    }

    &__Text {
        flex: 1 1 auto;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        #{$root}--Button & {
            font-weight: $bold;
        }

        #{$root}--Tag & {
            overflow: visible;
        }
    }

    &__Count {
        color: #a3a3a3;
        flex: 0 0 auto;
        padding-left: 5px;

        #{$root}--Button #{$root}__Input:checked ~ #{$root}__Label & {
            display: none;
        }

        #{$root}:not(#{$root}--Button) & {
            display: none;
        }
    }

    &__Close {
        // Copied from FilterEvent__Close
        position: relative;
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-left: 8px;
        transform: rotate(45deg);

        &::before,
        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 2px;
            margin: auto;
            background: black;
        }

        &::after {
            height: 100%;
            width: 2px;
        }

        &--Pink {
            &::before,
            &::after {
                background: palette(pink);
            }
        }

        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 19px;
        margin: auto;

        &::before,
        &::after {
            background: black;
        }

        #{$root}__Input--All ~ #{$root}__Label & {
            display: inline-block;
        }
    }
}
