@import 'Styles/Dansskolanentre/includes';

/**
*
* FilterEventGroup
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.FilterEventGroup {
    $root: &;

    margin-bottom: 16px;

    @include media(sm) {
        margin-bottom: 24px;
    }

    &__Title {
        font-family: $font-base;
        font-weight: $bold;
        margin-bottom: 8px;
        font-size: 1.4rem;
        line-height: 1.5;

        @include media(sm) {
            margin-bottom: 16px;
            font-size: 1.6rem;
        }

        #{$root}--Disabled & {
            opacity: .5;
        }
    }

    &__List {
        display: flex;

        &--Input {
            flex-wrap: wrap;
        }
    }

    &__Button {
        position: relative;
        display: inline-block;
        padding-left: 30px;
        margin-top: 0;
        color: $text-color;
        font-size: 1.6rem;
        font-weight: $regular;
        cursor: pointer;
        transition: color .2s ease-in-out;

        &:hover {
            text-decoration: underline;

            &::before {
                background: $red-60;
            }
        }

        &::before {
            content: '';
            position: absolute;
            display: inline-block;
            left: 0;
            top: 2px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: $primary-color;
            transition: background .2s ease-in-out;
        }

        &::after {
            content: '';
            position: absolute;
            display: inline-block;
            left: 5px;
            top: 7px;
            width: 10px;
            height: 10px;
            background-image: url('../#{$basepath}img/bg-icons/base/plus--white.svg');
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }

        &--Minus {
            &::after {
                background-image: url('../#{$basepath}img/bg-icons/base/minus--white.svg');
            }
        }
    }
}
