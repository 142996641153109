@import 'Styles/Base/includes';

/**
*
* FilterEventSelect
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.FilterEventSelect {
    $root: &;
    width: 100%;
    position: relative;

    &--Disabled {
        &::after {
            opacity: .3;
        }
    }

    &--educationLevel {
        @include media(sm) {
            max-width: 370px;
        }
    }

    &::before {
        content: '';
        width: 60px;
        display: block;
        position: absolute;
        top: 3px;
        bottom: 3px;
        right: 3px;
        background: white;
        pointer-events: none;
    }

    &::after {
        content: '';
        height: 9px;
        width: 14px;
        margin: auto;
        position: absolute;
        top: 0px;
        bottom: 0px;
        right: 20px;
        background: url(../#{$basepath}img/bg-icons/base/dropdown.svg) no-repeat center center;
        background-size: contain;
        pointer-events: none;
    }

    &__Select {
        width: 100%;
        padding: 18px 20px;
        color: black;
        font-size: 1.6rem;
        font-family: $font-base;
        text-overflow: '';
        text-indent: 0.01px;
        border: 1px solid #e9e9e9;
        border-radius: 0px;
        background: white;
        -webkit-appearance: none;
        -moz-appearance: none;

        option {
            color: black;
            font-size: 1.6rem;
            font-family: $font-base;
        }

        &:-moz-focusring {
            color: transparent;
            text-shadow: 0 0 0 black;
        }

        &::-ms-expand {
            display: none;
        }

        &[disabled] {
            color: rgba(black, .3);
            border-color: rgba(#e9e9e9, .7);
        }
    }
}
