@import 'Styles/Medborgarskolan/includes';

/**
*
* FilterEventGroup
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.FilterEventGroup {
    $root: &;

    margin-bottom: 12px;

    &__Title {
        margin-bottom: 18px;
        font-size: 1.6rem;
        line-height: 1.9;
    }

    &__List {
        #{$root}--Button & {
            display: flex;
            flex-flow: row wrap;
            margin-left: -8px;

            @include media(md) {
                margin-left: -16px;
            }
        }
    }

    &__Button {
        position: relative;
        display: inline-block;
        padding-bottom: 6px;
        padding-left: 28px;
        color: $blue;
        font-size: 1.6rem;
        font-weight: $bold;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }

        &::before,
        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 12px;
            left: 0;
            width: 16px;
            height: 2px;
            margin: auto;
            border-radius: 1px;
            background: $blue;
        }

        &::after {
            transform: rotate(90deg);
        }

        &--Minus {
            &::after {
                display: none;
            }
        }
    }
}
