@import 'Styles/Pahlmans/includes';

/**
*
* EventSearchInput
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import './EventSearchInput.Base.module.scss';

.EventSearchInput {
    $root: &;

    margin-top: 22px;

    &__Button {
        @include u-button(primary);

        height: 72px;
        min-width: 72px;

        @include media(md) {
            height: 80px;
            min-width: 80px;
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 18px;
            height: 18px;
            margin: auto;
            background: url('#{$basepath}img/bg-icons/base/search--white.svg') no-repeat center center;
            background-size: contain;
            z-index: 1;
        }

        span {
            position: absolute !important;
        }
    }
}
