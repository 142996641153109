@import 'Styles/Medborgarskolan/includes';

/**
*
* EventSearchInput
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import "./EventSearchInput.Base.module.scss";

.EventSearchInput {
    $root: &;

    @include media(md) {
        padding: 4px;
        border-radius: 100px;
        background: white;
    }

    &__InputField {
        margin-bottom: 16px;

        @include media(md) {
            flex: 1 1 auto;
        }

        #{$root}--OnlySearch & {
            width: 100%;
            margin-right: 0;
            margin-bottom: 0;

            @include media(md) {
                margin-right: 16px;
            }
        }
    }

    &__InputContainer {
        @include media(md) {
            height: 56px;
        }

        #{$root}--OnlySearch & {
            max-width: calc(100% - 68px);

            @include media(md) {
                max-width: none;
            }
        }
    }

    &__SelectContainer {
        min-height: 56px;
        margin: 0;

        @include media(md) {
            margin-right: 16px;
        }

        &:first-child {
            #{$root}:not(#{$root}--Singular):not(#{$root}--Search) & {
                margin-bottom: 16px;

                @include media(md) {
                    margin-bottom: 0;
                }
            }
        }

        &:last-child {
            #{$root}--Search:not(#{$root}--Singular) & {
                width: calc(100% - 68px);

                @include media(md) {
                    width: calc(100% / 3);
                    max-width: 230px;
                }
            }

            #{$root}:not(#{$root}--Singular):not(#{$root}--Search) & {
                width: calc(100% - 68px);

                @include media(md) {
                    width: calc(100% / 3);
                }
            }
        }

        :global {
            .Select {
                border-radius: 29px;

                .Select__control {
                    height: 56px;
                    border: none;
                    background: transparent;
                    border-radius: 29px;

                    @include media(md) {
                        height: 56px;
                        border-radius: 0;
                    }

                    &:hover {
                        box-shadow: none;
                    }

                    // Background for dropdown in mobile
                    &::before {
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: auto;
                        width: 100%;
                        height: 100%;
                        margin: auto;
                        border: none;
                        border-radius: 29px;
                        background: white;

                        // Line seperating dropdown in desktop
                        @include media(md) {
                            width: 1px;
                            height: 40px;
                            left: 0;
                            border-radius: 0;
                            background: $grey-30;
                        }

                        #{$root}__SelectContainer:first-child & {
                            @include media(md) {
                                opacity: 0;
                            }
                        }
                    }
                }

                &--is-focused {
                    outline: 3px solid $focus-color;

                    .Select__control {
                        background: transparent;

                        // Hide line in desktop on focus
                        &::before {
                            @include media(md) {
                                opacity: 0;
                            }
                        }
                    }
                }

                .Select__menu {
                    margin-top: 8px;
                    width: 100%;
                    max-height: 250px;
                    border-radius: 8px;
                    border: none;
                    box-shadow: 0px 2px 24px 0px rgba(black, .08);
                    overflow: hidden;

                    @include media(md) {
                        margin: 12px 0 0 0;
                        min-width: 370px;
                        display: block;
                    }
                }

                .Select__option {
                    display: flex;
                    align-items: center;
                    font-size: 1.6rem;
                    color: $text-color;
                    background: white;

                    &--is-disabled {
                        line-height: 24px;
                        font-size: 1.4rem;
                        font-weight: 400;
                        text-transform: inherit;
                        color: $grey-60;
                    }

                    &--is-focused {
                        background: $blue-20;
                    }

                    &--is-selected {
                        background: $blue-20;
                    }
                }

                .Select__option--is-disabled ~ .Select__option:not(.Select__option--is-disabled) {
                    padding-left: 16px;
                }

                .Select__menu-list {
                    max-height: 250px;
                    border-radius: 8px;
                }

                .Select__dropdown-indicator {
                    padding-right: 23px;

                    &::before {
                        width: 20px;
                        height: 20px;
                        top: 3px;
                        background: url("#{$basepath}img/bg-icons/medborgarskolan/new/chevron--blue.svg")
                            no-repeat center center;
                        background-size: contain;
                        opacity: 1;
                        transform: rotate(90deg);
                    }
                }

                &--is-open > .Select__control .Select__dropdown-indicator::before {
                    top: 3px;
                    transform: rotate(-90deg);
                }
            }
        }
    }

    &__Button {
        width: 56px !important;
        height: 56px !important;
        margin: 0;
        padding: 16px;
        font-size: 1.8rem;
        font-weight: 500;
        border-radius: 100px;
        background: $blue;

        @include media(md) {
            width: auto !important;
            padding: 17px 32px;
        }

        &:hover {
            background: $blue-90;
        }

        &:focus-visible {
            outline-style: solid;
        }

        &::after {
            content: "";
            display: inline-block;
            width: 24px;
            height: 24px;
            margin: -4px -1px;
            background: url("#{$basepath}img/bg-icons/medborgarskolan/new/search--white.svg")
                no-repeat center center;
            background-size: contain;

            @include media(md) {
                margin-left: 8px;
            }
        }

        > :global(.sr-only) {
            display: none;
        }
    }

    &__ButtonText {
        @include media(md) {
            display: inline-block;
        }
    }
}
