@import 'Styles/Base/includes';

/**
*
* EventSearchInput
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.EventSearchInput {
    $root: &;

    &__Container {
        position: relative;

        @include media(md) {
            display: flex;
            justify-content: center;
        }

        #{$root}--OnlySearch & {
            display: flex;
        }
    }

    &__InputContainer {
        display: flex;

        @include media(md) {
            height: 80px;
            width: 100%;
            transition: all .15s ease-in-out;
        }

        #{$root}:not(#{$root}--OnlySearch) & {
            flex-wrap: wrap;

            @include media(md) {
                flex-wrap: nowrap;
            }
        }

        #{$root}--OnlySearch & {
            flex: 1 0 auto;

            @include media(md) {
                flex: 0 1 auto;
            }
        }
    }

    &__InputField {
        flex: 0 1 auto;
        margin-bottom: 12px;
        position: relative;

        #{$root}:not(#{$root}--OnlySearch) & {
            width: 100%;

            @include media(md) {
                margin-right: 13px;
                width: calc((100% * 2/3) - 13px);
            }
        }

        #{$root}--OnlySearch & {
            width: calc(100% - 13px);
            margin-right: 13px;
        }
    }

    &__SelectContainer {
        flex: 0 1 auto;
        width: 100%;
        margin-bottom: 12px;
        min-height: 60px;
        position: relative;
        z-index: z('EventSearchInput__Select');

        @include media(md) {
            flex: 1 1 auto;
            width: calc(100% / 3);
            margin-right: 13px;
        }

        &:last-child {
            width: calc(100% - 72px);

            @include media(md) {
                width: calc(100% / 3);
            }
        }

        &:first-child {
            :global {
                .Select {
                    .Select__control {
                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }

        :global {
            .Select {
                width: 100%;
                text-align: left;
                z-index: z('EventSearchInput__Select');

                &--is-open {
                    z-index: z('EventSearchInput__Select')+1;
                }

                .Select__control {
                    border-radius: 3px;
                    transition: box-shadow .2s;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        border-radius: 3px;
                        border: 1px solid black;
                        transition: border-color .2s;
                    }

                    @include media(md) {
                        height: 78px;
                        box-shadow: none;

                        &:hover {
                            box-shadow: none;
                        }
                    }
                }

                .Select__indicators {
                    position: relative;
                }

                &--is-focused {
                    .Select__control::before {
                        border-color: black;
                    }
                }
            }
        }
    }

    &__Button {
        position: absolute;
        bottom: 0;
        right: 0;
        flex: 0 0 auto;
        width: 60px;
        height: 60px;
        margin-bottom: 12px;
        color: white;
        font-family: $font-base;
        border: 0;
        border-radius: 3px;
        background-color: black;
        background-image: url('#{$basepath}img/bg-icons/base/search--white.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 18px auto;
        cursor: pointer;
        transition: all .15s ease-in-out;

        @include media(md) {
            position: relative;
            width: 80px;
            height: 80px;
            background-size: 21px auto;
        }

        #{$root}--Small & {
            height: 50px;
            width: 50px;

            @include media(md) {
                width: 60px;
                height: 60px;
            }
        }
    }

    &__ButtonText {
        display: none;
    }
}
