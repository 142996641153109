@import 'Styles/Medborgarskolan/includes';

/**
*
* EventSearchInputField
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'EventSearchInputField.Base.module.scss';

.EventSearchInputField {
    $root: &;

    &__Input {
        height: 56px;
        font-weight: $normal;
        font-family: $font-base;
        background-image: url('../#{$basepath}img/bg-icons/medborgarskolan/new/search--blue.svg');
        background-size: 24px 24px;
        border: none;
        border-radius: 100px;

        &::placeholder {
        }

        &:focus-visible {
            outline-style: solid;
        }

        &:focus::placeholder {
            color: $placeholder;
        }
    }

    &__Suggest {
        display: none;
    }

    &__Value {
        &:hover {
            background: $blue-20;
        }

        em {
            font-weight: $bold;
        }
    }
}
