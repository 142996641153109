@import 'Styles/Medborgarskolan/includes';

/**
*
* FilterEventSelect
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import './FilterEventSelect.Base.module.scss';

.FilterEventSelect {
    $root: &;

    &__Select {
        font-weight: $bold;
        border-radius: 4px;
        border-color: $grey;

        #{$root}--educationLevel & {
            padding: 10px;

            @include media(sm) {
                padding: 18px 20px
            }
        }
    }
}
