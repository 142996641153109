@import 'Styles/Tillskararakademin/includes';

/**
*
* HeroPage
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'HeroPage.Base.module';

.HeroPage {
    $root: &;

    &__Background {
        background-color: palette(black);

        &::after {
            background: black;
        }
    }

    &__Container {
        padding-top: 130px;
        padding-bottom: 130px;
        display: flex;
        justify-content: center;
        align-items: center;

        @include media(md) {
            min-height: 530px;
            padding-top: 160px;
            padding-bottom: 160px;
        }

        #{$root}--PageHome & {
            padding-top: 150px;
            padding-bottom: 160px;

            @include media(md) {
                padding-top: 160px;
                padding-bottom: 180px;
            }
        }
    }

    &__TextContainer {
        max-width: 100%; /* for IE 10 */
        @include media(md) {
            padding: 0 60px;
        }
    }

    &__Title {
        font-size: 4rem;
        font-weight: $bold;
        line-height: 1.1;
        color: palette(white);
        text-align: center;
        hyphens: manual;

        @include media(md) {
            font-size: 8rem;
        }
    }

    &__Text {
        margin: 10px auto 0;
        text-align: center;
        max-width: 740px;
        font-size: 1.8rem;
        color: palette(white);
    }
}
