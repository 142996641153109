@import 'Styles/Pahlmans/includes';

/**
*
* EventSearchInputField
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'EventSearchInputField.Base.module.scss';

.EventSearchInputField {
    $root: &;

    &__Container {

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            border: 2px solid $primary-color;
            border-radius: 4px;
            opacity: 0;
            pointer-events: none;
            transition: opacity .3s ease-in-out;
            z-index: 13;
        }

        &:focus-within {
            &::after {
                opacity: 1;
            }
        }
    }

    &__Input {
        height: 72px;
        padding: 26px 20px 26px 50px;
        color: palette(black, 86);
        font-size: 1.6rem;
        font-weight: normal;
        font-family: $font-base;
        text-overflow: ellipsis;
        border-color: palette(black, 15);
        border-radius: 4px;
        background-image: url('../#{$basepath}img/bg-icons/pahlmans/search--blue.svg');

        &:focus {
            border-color: palette(black, 15);
        }

        &::placeholder {
            color: palette(black, 72);
        }

        @include media(md) {
            height: 80px;
            padding: 26px 26px 26px 64px;
            font-size: 1.8rem;
        }
    }

    &__Suggest {
        border-color: palette(black, 15);
    }

    &__Value {
        &:hover {
            background: palette(black, 5);
        }
    }
}
