@import 'Styles/Pahlmans/includes';

/**
*
* FilterEventInput
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.FilterEventInput {
    $root: &;

    display: block;
    margin-right: 10px;
    padding-bottom: 10px;
    font-size: 1.4rem;
    font-weight: $normal;
    line-height: 17px;
    width: calc(100% - 10px);

    @include media(xs) {
        width: calc(50% - 10px);
    }

    @include media(sm) {
        width: calc(#{percentage(1/3)} - 15px);
    }

    @include media(md) {
        width: calc(25% - 15px);
    }

    &--day {
        width: calc(50% - 10px);

        @include media(xs) {
            width: calc(#{percentage(1/3)} - 10px);
        }

        @include media(sm) {
            width: 100px;
        }

        @include media(md) {
            width: 100px;
        }
    }

    &--Tag {
        display: inline-block;
        width: auto!important;
        line-height: 1;
        padding: 0;
        margin-right: 12px!important;
        margin-bottom: 8px;
    }

    &--Disabled {
        opacity: .6;
    }

    &__Input {
        display: none;
    }

    &__Label {
        position: relative;
        display: flex;
        padding-left: 30px;
        transition: all .2s;

        #{$root}:not(#{$root}--Disabled) & {
            cursor: pointer;
        }

        #{$root}--Button & {
            padding: 13px 19px;
            border: 1px solid palette(black, 15);
            border-radius: 3px;
            background: white;
        }

        #{$root}--Button #{$root}__Input:checked ~ & {
            background-color: palette(black, 5);
        }

        #{$root}--Tag & {
            padding: 5px;
            color: palette(black, 86);
            border: 1px solid palette(black, 4);
            background-color: palette(black, 4);
            transition: all .2s ease-in-out;
        }

        #{$root}--Tag:hover & {
            color: $secondary-color;
            border-color: $secondary-color;
            background-color: white;
        }

        #{$root}--Tag #{$root}__Input:checked ~ & {
            color: white;
            border-color: $secondary-color;
            background-color: $secondary-color;
        }

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 16px;
            height: 16px;
            border: 1px solid palette(black, 20);
            border-radius: 2px;
            background: white;
            transition: opacity .2s;

            #{$root}--Tag & {
                display: none;
            }
        }

        &::after {
            border-color: $secondary-color;
            background: $secondary-color url(../#{$basepath}img/bg-icons/pahlmans/check--white.svg);
            background-repeat: no-repeat;
            background-position: center center;
            opacity: 0;

            #{$root}__Input:checked ~ & {
                opacity: 1;
            }
        }
    }

    &__Text {
        flex: 1 1 auto;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &__Count {
        display: none;
    }
}
