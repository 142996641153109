@import 'Styles/Kulturama/includes';

/**
*
* EventSearchInput
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import './EventSearchInput.Base.module.scss';

.EventSearchInput {
    $root: &;

    &__SelectContainer {
        :global {
            .Select {
                .Select__control {
                    height: 60px;
                    font-weight: $bold;
                    border-radius: 4px;
                    border: none;

                    @include media(md) {
                        height: 80px;
                    }

                    &::before {
                        height: calc(100% - 2px);
                        border-radius: 4px;
                        border-color: palette(black, 11);
                    }

                    &--is-focused {
                        &::before {
                            border-color: palette(black, 16) !important;
                        }
                    }
                }

                .Select__placeholder {
                    color: black;
                }

                .Select__value-container {
                    height: 60px;

                    @include media(md) {
                        height: 80px;
                        font-size: 1.8rem;
                        // line-height: 80px;
                    }
                }

                .Select__option {
                    &--is-selected {
                        color: black;
                        background: rgba(palette(pink, 15), .2);
                    }

                    &--is-focused {
                        color: black;
                        background: rgba(palette(pink, 15), .4);
                    }
                }
            }
        }
    }


    &__Button {
        border-radius: 4px;
        background-color: palette(pink);

        &:hover {
            background-color: palette(pink, hover);
        }
    }
}
