@import 'Styles/Kulturama/includes';

/**
*
* FilterEventAccordian
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.FilterEventAccordian {
    $root: &;

    // TODO: Change animation to something more reliable
    overflow: hidden;
    background: white;
    border-bottom: 1px solid #d9d9d9;

    &__Container {
        @include u-wrap(site);

        padding-top: 30px;
        padding-bottom: 30px;
    }

    &__SubmitContainer {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        padding: 10px 0;
        text-align: center;

        @include media(xs) {
            flex-wrap: nowrap;
        }
    }

    &__Submit {
        @include u-button(pink solid);

        width: auto;
        max-width: none;

        @include media(xs) {
            width: auto;
        }

        @include media(sm) {
            width: auto;
        }
    }

    &__Reset {
        position: relative;
        top: 5px;
        align-self: center;
        margin-bottom: 10px;
        margin-left: 30px;
        color: palette(black, 80);
        text-decoration: underline;
        font-size: 1.4rem;
        cursor: pointer;

        @include media(xs) {
            margin-bottom: 0;
            top: 0;
        }
    }

    &__List {
        display: flex;
        flex-flow: row wrap;
        margin-left: -10px;

        @include media(sm) {
            margin-left: -15px;
        }
    }

    &__ItemGroup {
        display: flex;
        flex-flow: row wrap;
        width: calc(100% - 10px);

        @include media(sm) {
            width: calc(100% - 15px);
        }

        :global(.PageEventSearchKulturama) & {
            @include media(md) {
                width: calc(50% - 15px);
            }
        }
    }

    &__Item {
        flex: 1 1 auto;
        width: calc(100% - 10px);
        margin-left: 10px;

        @include media(sm) {
            width: calc(#{percentage(1/3)} - 15px);
            margin-left: 15px;
        }

        :global(.PageEventSearchKulturama) #{$root}__ItemGroup & {
            @include media(sm) {
                width: calc(50% - 15px);
            }
        }

        :global(.PageArchiveSchool) & {
            @include media(sm) {
                width: calc(42% - 15px);
            }

            &:last-of-type {
                @include media(sm) {
                    width: calc(58% - 15px);
                }
            }
        }

        #{$root}__list > &:last-child {
            @include media(sm) {
                width: calc(100% - 15px);
            }
        }

        &--Wide {
            @include media(sm) {
                margin-right: 15px;
                width: calc(100% - 15px);
            }

            @include media(md) {
                width: calc(50% - 15px);
            }

            :global(.PageEventSearchKulturama) & {

                @include media(md) {
                    margin-right: 15px;
                    width: calc(50% - 30px);
                }
            }
        }
    }
}
