@import 'Styles/Medborgarskolan/includes';

/**
*
* HeroPage
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'HeroPage.Base.module';

.HeroPage {
    $root: &;

    &--Border {
        border-bottom: 6px solid $green;
    }

    &__Background {
        &::after {
            background: black;
            opacity: .5;
        }

        #{$root}--Blurred & {
            overflow: hidden;

            img {
                position: absolute;
                top: -5px;
                left: -10px;
                right: -10px;
                bottom: -5px;
                width: calc(100% + 20px);
                height: calc(100% + 10px);
                filter: blur(5px);
            }
        }
    }

    &__BreakoutBreadcrumbs {
        @include u-wrap(site);

        position: relative;
        display: block;
        padding-top: 12px;
        z-index: 1;
    }

    &__Container {
        padding-top: 80px;
        padding-bottom: 40px;

        @include media(md) {
            padding-top: 100px;
            min-height: 350px;
        }

        #{$root}--Label & {
            padding-top: 40px;

            @include media(md) {
                padding-top: 50px;
            }
        }
    }

    &__Breadcrumbs {
        display: none;
    }

    &__Label {
        display: inline-block;
        margin: 25px 0 12px 0;
        padding: 6px 10px;
        color: white;
        font-size: 1.2rem;
        font-weight: $bold;
        letter-spacing: 1px;
        text-transform: uppercase;
        line-height: 1.5;
        border-radius: 31px;
        background: $purple-80;

        @include media(sm) {
            margin-bottom: 16px;
            padding: 5px 12px;
            font-size: 1.4rem;
        }
    }

    &__Title {
        max-width: map-get($maxwidths, title);
        color: white;
        font-size: 4.4rem;
        font-weight: $bold;
        line-height: 1.24;

        @include media(md) {
            font-size: 6.4rem;
        }
    }

    &__Text {
        margin-top: 12px;
        max-width: map-get($maxwidths, content);
        color: white;
        font-size: 1.6rem;
        font-weight: $medium;

        @include media(md) {
            margin-top: 20px;
            font-size: 2rem;
        }
    }
}
