@import 'Styles/Pahlmans/includes';

/**
*
* HeroPage
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'HeroPage.Base.module';

.HeroPage {
    $root: &;

    &__Background {
        background-color: black;

        &::after {
            background: black;
        }

        #{$root}--Blurred & {
            overflow: hidden;

            img {
                position: absolute;
                top: -5px;
                left: -10px;
                right: -10px;
                bottom: -5px;
                width: calc(100% + 20px);
                height: calc(100% + 10px);
                filter: blur(5px);
            }

            /*&::before {
                content: '';
                position: absolute;
                top: -5px;
                bottom: -5px;
                left: -5px;
                right: -5px;
                display: block;
                background: inherit;
                filter: blur(5px);
            }*/
        }
    }

    &__Container {
        padding-top: 130px;
        padding-bottom: 130px;
        text-align: center;

        @include media(md) {
            min-height: 490px;
            padding-top: 190px;
            padding-bottom: 210px;
        }

        #{$root}--PageHome & {
            padding-top: 150px;
            padding-bottom: 160px;

            @include media(md) {
                padding-top: 160px;
                padding-bottom: 180px;
            }
        }
    }

    &__TextContainer {
        @include media(md) {
            padding: 0 60px;
        }
    }

    &__Title {
        font-size: 4rem;
        font-weight: $normal;
        line-height: 1.1;
        color: white;

        @include media(md) {
            font-size: 7rem;
        }
    }

    &__Text {
        margin: 10px auto 0;
        max-width: 740px;
        font-size: 1.8rem;
        color: white;
        font-family: $font-base;
        font-weight: $normal;
        @include media(md) {
            font-size: 2rem;
        }
    }
}
