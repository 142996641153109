@import 'Styles/Kulturama/includes';

/**
*
* EventSearchInputField
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'EventSearchInputField.Base.module.scss';

.EventSearchInputField {
    $root: &;

    &__Input {
        padding-right: 8px;
        text-overflow: ellipsis;
        border-color: palette(black, 11);
        border-radius: 4px;

        &:focus {
            border-color: palette(black, 16);
        }

        &:focus::placeholder {
            color: $placeholder;
        }
    }

    &__Suggest {
        border-color: palette(black, 11);
    }

    &__Value {
        &:hover {
            background: palette(black, 5);
        }
    }
}
