@import 'Styles/Medborgarskolan/includes';

/**
*
* FilterEventInput
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.FilterEventInput {
    $root: &;

    display: block;
    padding-bottom: 8px;
    font-size: 1.4rem;
    line-height: 17px;

    @include media(md) {
        padding-bottom: 16px;
    }

    &--Button {
        width: calc(100% - 8px);
        margin-left: 8px;
        padding-bottom: 8px;

        @include media(xs) {
            width: calc(50% - 8px);
        }

        @include media(sm) {
            width: calc(#{percentage(1/3)} - 8px);
        }

        @include media(md) {
            width: calc(50% - 16px);
            margin-left: 16px;
            padding-bottom: 16px;
        }
    }

    &--Disabled {
        opacity: .6;
    }

    &--Tag {
        display: inline-block;
        width: auto!important;
        line-height: 1;
        padding: 0;
        margin-right: 12px!important;
        margin-bottom: 8px;
        border: none;
    }

    &__Input {
        display: none;
    }

    &__Label {
        position: relative;
        display: flex;
        padding-left: 30px;
        transition: all .2s;

        #{$root}:not(#{$root}--Button):not(#{$root}--Tag) & {
            padding-bottom: 2px;
        }

        #{$root}:not(#{$root}--Disabled) & {
            cursor: pointer;

            &:hover::before {
                border-color: $blue;
                box-shadow: 0 0 0 1px $blue;
            }
        }

        #{$root}--Button & {
            padding: 13px 18px;
            color: $blue;
            border: 1px solid $grey;
            border-radius: 4px;
            background: white;

            @include media(sm) {
                padding: 18px 20px;
            }
        }

        #{$root}--Button:not(#{$root}--Disabled) & {
            &:hover {
                border-color: $blue;
                box-shadow: 0 0 0 1px $blue;
            }
        }

        #{$root}--Button#{$root}--Disabled & {
            color: $grey-70;
        }

        #{$root}--Button #{$root}__Input:checked ~ & {
            color: white;
            border-color: $blue;
            background: $blue;
        }

        #{$root}--Tag & {
            padding: 3px 12px;
            border: 1px solid $grey;
            border-radius: 12px;
            background: white;
            transition: all .2s ease-in-out;
        }

        #{$root}--Tag:hover & {
            color: $blue;
            border-color: $blue;
            background-color: white;
        }

        #{$root}--Tag #{$root}__Input:checked ~ & {
            color: white;
            border-color: $blue;
            background-color: $blue;
        }

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 16px;
            height: 16px;
            border: 1px solid $grey;
            border-radius: 3px;
            background: white;
            transition: all $transition;

            #{$root}--Button &,
            #{$root}--Tag & {
                display: none;
            }

            #{$root}--Disabled & {
                background: $grey-30;
            }
        }

        &::after {
            border-color: $blue;
            background: $blue url('../#{$basepath}img/bg-icons/medborgarskolan/new/check--white.svg');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 12px;
            box-shadow: 0 0 0 1px $blue;
            opacity: 0;

            #{$root}__Input:checked ~ & {
                opacity: 1;
            }
        }
    }

    &__Text {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        #{$root}--Tag & {
            overflow: visible;
        }
    }

    &__Count {
        flex: 0 0 auto;
        padding-left: 3px;

        #{$root}:not(#{$root}--Button) & {
            display: none;
        }

        &::before {
            content: '(';
        }

        &::after {
            content: ')';
        }
    }

    &__Close {
        // Copied from FilterEvent__Close
        position: relative;
        display: inline-block;
        width: 18px;
        height: 18px;
        margin-left: 8px;
        transform: rotate(45deg);

        &::before,
        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 2px;
            margin: auto;
            border-radius: 1px;
            background: $blue;
        }

        &::after {
            height: 100%;
            width: 2px;
        }

        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 19px;
        margin: auto;

        &::before,
        &::after {
            border-radius: 1px;
            background: white;
        }

        #{$root}__Input--All ~ #{$root}__Label & {
            display: inline-block;
        }
    }
}
