@import 'Styles/Dansskolanentre/includes';

/**
*
* FilterEventAccordian
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.FilterEventAccordian {
    $root: &;

    // TODO: Change animation to something more reliable
    overflow: hidden;
    background: $grey-5;
    border-bottom: 1px solid $grey-40;

    &__Container {
        @include u-wrap(site);

        padding-top: 24px;
        padding-bottom: 24px;

        @include media(sm) {
            padding-top: 40px;
            padding-bottom: 40px;
        }
    }

    &__SubmitContainer {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        padding: 10px 0;
        text-align: center;

        @include media(xs) {
            flex-wrap: nowrap;
        }
    }

    &__Submit {
        @include u-button(solid);

        width: auto;
        max-width: none;

        @include media(xs) {
            width: auto;
        }

        @include media(sm) {
            width: auto;
        }
    }

    &__Reset {
        position: relative;
        top: 5px;
        align-self: center;
        margin-bottom: 10px;
        margin-left: 30px;
        color: $text-color;
        font-size: 1.4rem;
        font-weight: $medium;
        cursor: pointer;
        text-decoration: underline;

        @include media(xs) {
            margin-bottom: 0;
            top: 0;
        }

    }

    &__List {
        display: flex;
        flex-flow: row wrap;
        margin-left: -10px;

        @include media(sm) {
            margin-left: -15px;
        }
    }

    &__Item {
        flex: 1 1 auto;
        width: calc(100% - 10px);
        margin-left: 10px;

        @include media(xs) {
            width: calc(50% - 10px);
        }

        @include media(sm) {
            width: calc(50% - 15px);
            margin-left: 15px;
        }

        &--Wide {
            @include media(xs) {
                width: calc(100% - 10px);
            }

            @include media(sm) {
                width: calc(100% - 15px);
            }
        }
    }
}
