@import 'Styles/Base/includes';

/**
*
* Video
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.Video {
    $root: &;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    opacity: 0;

    &--Loaded {
        opacity: 1 !important;
    }

    &__Player {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100% !important;
        height: 100% !important;

        #{$root}--Cover#{$root}:not(#{$root}--HasProportion) & {
            width: auto !important;
            height: auto !important;
        }

        #{$root}--Cover#{$root}--HasProportion#{$root}--Landscape & {
            width: 100% !important;
            height: 500% !important;
        }

        #{$root}--Cover#{$root}--HasProportion#{$root}--Portrait & {
            width: 500% !important;
            height: 100% !important;
        }

        div {
            width: 100%;
            height: 100%;
        }

        iframe {
            display: block;
            width: 100%;
            height: 100%;

            #{$root}--Cover & {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    &__VideoMessage {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}
