@import 'Styles/Pahlmans/includes';

/**
*
* FilterEventGroup
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.FilterEventGroup {
    $root: &;

    margin-bottom: 10px;

    &__Title {
        margin-bottom: 7px;
        font-size: 1.4rem;
        line-height: 1.9;
        font-family: $font-base;
    }

    &__List {
        display: flex;

        &--Input {
            flex-wrap: wrap;

            @include media(sm){
                flex-wrap: nowrap;
            }

            :global(.PageArchiveSchool) & {
                flex-wrap: wrap;
            }
        }

        &--Select {

        }

        #{$root}--Button & {
            display: flex;
            flex-flow: row wrap;
            margin-left: -9px;
        }
    }

    &__Button {
        position: relative;
        display: inline-block;
        padding-bottom: 6px;
        padding-left: 30px;
        margin-top: 8px;
        color: black;
        font-size: 1.4rem;
        font-weight: $normal;
        cursor: pointer;
        transition: color .2s ease-in-out;

        &:hover {
            color: $secondary-color;
        }

        &::before {
            content: '';
            position: absolute;
            display: inline-block;
            left: 0;
            top: 0;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: palette(black, 3);
        }

        &::after {
            content: '';
            position: absolute;
            display: inline-block;
            left: 5px;
            top: 5px;
            width: 10px;
            height: 10px;
            background-image: url('../#{$basepath}img/bg-icons/base/plus--gray.svg');
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }

        &--Minus {
            &::after {
                background-image: url('../#{$basepath}img/bg-icons/base/minus--gray.svg');
            }
        }
    }
}
