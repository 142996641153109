@import 'Styles/Dansskolanentre/includes';

/**
*
* FilterEventInput
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.FilterEventInput {
    $root: &;

    display: block;
    margin-right: 10px;
    padding-bottom: 10px;
    font-size: 1.4rem;
    font-weight: $normal;
    line-height: 17px;
    color: black;
    width: 100%;

    @include media(xs) {
        width: calc(50% - 10px);
    }

    @include media(sm) {
        width: auto;
        margin-right: 20px;
    }

    &--Grouped {
        width: calc(50% - 10px);

        @include media(xs) {
            width: calc(#{percentage(1/3)} - 10px);
        }

        @include media(sm) {
            width: 100%;
            margin-right: 10px;
        }
    }

    &--Tag {
        display: inline-block;
        width: auto !important;
        line-height: 1;
        padding: 0;
        margin-right: 8px !important;
        margin-bottom: 8px;
    }

    &--Disabled {
        opacity: .6;
    }

    &__Input {
        display: none;
    }

    &__Label {
        position: relative;
        display: flex;
        padding-left: 30px;
        transition: all .2s ease-in-out;

        #{$root}:not(#{$root}--Disabled) & {
            cursor: pointer;
        }

        #{$root}--Tag & {
            padding: 4px 8px;
            color: $text-color;
            border: 1px solid $red-5;
            border-radius: 4px;
            background-color: $red-5;
        }

        #{$root}--Tag:not(#{$root}--Disabled):hover & {
            border-color: $red-15;
            background-color: $red-15;

            @media(hover: none) {
                border-color: $red-5;
                background-color: $red-5;
            }
        }

        #{$root}--Tag #{$root}__Input:checked ~ & {
            color: white;
            border-color: $red;
            background-color: $red;
        }

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 16px;
            height: 16px;
            border: 1px solid $grey-50;
            border-radius: 3px;
            background-color: white;
            transition: opacity .2s;

            #{$root}--Tag & {
                display: none;
            }
        }

        &::after {
            border-color: $primary-color;
            background: $primary-color url(../#{$basepath}img/bg-icons/dansskolanentre/check--white.svg);
            background-repeat: no-repeat;
            background-position: center center;
            opacity: 0;

            #{$root}__Input:checked ~ & {
                opacity: 1;
            }
        }
    }

    &__Text {
        flex: 1 1 auto;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 1.6rem;
        font-weight: $light;
        line-height: 1.2;

        #{$root}--Tag & {
            font-size: 1.4rem;
        }
    }

    &__Count {
        display: none;
    }
}
