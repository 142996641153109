@import 'Styles/Base/includes';

/**
*
* EventSearchInputField
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.EventSearchInputField {
    $root: &;

    width: 100%;

    &__Container {
        display: block;
        position: relative;
    }

    &__Input {
        position: relative;
        height: 60px;
        border: 0;
        font-family: $font-base;
        width: 100%;
        padding-left: 47px;
        padding-right: 16px;
        font-size: 1.6rem;
        font-weight: $bold;
        color: $text-color;
        border: 1px solid black;
        border-radius: 3px;
        background-color: white;
        background-image: url('../#{$basepath}img/bg-icons/base/search--gray.svg');
        background-position: center left 16px;
        background-repeat: no-repeat;
        background-size: 16px 17px;
        -webkit-appearance: none;
        z-index: z(EventSearchInput__Search);

        &:focus {
            border-color: black;
        }

        &::placeholder {
            color: $text-color;
        }

        @include media(md) {
            height: 80px;
            font-size: 1.8rem;
            box-shadow: none;
            transition: border-color .2s;
        }

        @include media(lg) {
            padding-left: 64px;
            background-position: center left 28px;
        }
    }

    &__Suggest {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        padding: 20px 40px 20px 0;
        font-size: 1.6rem;
        color: $text-color;
        text-align: left;
        border: 1px solid black;
        border-top: 0;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        background: white;
        opacity: 0;
        transition: opacity .3s ease-in-out;
        z-index: z(EventSearchInput__Suggest);

        #{$root}--Open & {
            opacity: 1;
        }
    }

    &__List {
        max-height: 200px;
        overflow-y: auto;
    }

    &__Checkbox {
        display: none;
    }

    &__Value {
        padding: 11px 32px 11px 47px;
        position: relative;
        display: block;
        width: 100%;

        em {
            font-weight: $bold;
        }

        @include media(lg) {
            padding-left: 64px;
        }
    }
}
